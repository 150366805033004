*,
*::before,
*::after {
  box-sizing: border-box;
  color: rgb(10, 10, 10);
  font-family: 'Courier New', Courier, monospace;
  /* color: #f712c */
}

body {
  text-align: center;
  background-color: rgb(241, 233, 233) /* #212322; */
  
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
} 

.left-side {
  max-width: 70vw;
}

.left-side > img {
  margin-top: 130px;
}

.left-side > p {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  line-height: 25px;
  font-weight: lighter;
}

.left-side .social-links a {
  margin: 10px;
}

.App-logo {
  max-width: 300px;
}

.subscribe {
  padding: 20px;
}

@media (max-width: 480px) {
  .left-side>img {
      margin-top: 280px;
    }


}